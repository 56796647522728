<template>
  <div id="main-wrapper">
    <div class="main-content ">
      <v-container>
        <v-row>
          <v-col cols="12" >
            <div style="position:relative">
              <input id="search-hint" v-model="search" v-on:keyup="findProduct()" type="text" placeholder="یک یا چند عبارت تایپ کنید ..." autocomplete="off">
              <v-icon size="25" style="position:absolute;top:calc(50% - 12px);right:0px" @click="$router.go(-1)">mdi-arrow-right</v-icon>
            </div>
          </v-col>
          <v-col cols="12">
            <div id="search-list">
              <ul>
                <li class="d-flex algin-center" v-for="(srch,i) in searchLists" :key="i">
                  <p class="mb-0" style="padding: 5px;vertical-align: middle;">
                    <a @click="$router.push({path:'/news/'+srch.rawId})" class="font-small text-white decoration-none center">
                      <img  :src="siteUrl+'uploads/media/'+srch.img" width="100px"/><br>
                      <span class="result-title" style="text-align: justify-all">{{helperExcerpt(srch.text,55)}}</span><br/>
                    </a>

                    <!-- <span class="result-cat">محصولات دیجیتال | موبایل | گوشی های میان رده</span>-->
                  </p>
                </li>
                <li v-if="!searchLists.length">
                  <p> هیچ موردی یافت نشد</p>
                </li>
              </ul>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

import {mapGetters} from 'vuex'
export default {
  name: 'search',
  components: {


  },
  data () {
    return {
      sheet: false,
      search:'',
      searchLists:[],
      searchLoading:false,
      searchEmpty:false,

    }
  },
  mounted() {
    let self = this;
  },
  computed:{
    ...mapGetters(['baseUrl','siteUrl',"auth"]),
  },
  methods:{
    findProduct(){
      let self = this;
      this.helperPost('/xxx',{
        q:this.search,
      }).then(function(r){
        //console.log(r);
        if(r.data.result) {
          self.searchLists = r.data.data.items;
        }
        else
        {
          self.searchLists =[];
        }

      })
    },


  }
}
</script>

<style>
#search-hint{
  width: 100%!important;
  border-bottom: solid 2px #ef394f;
  height: 45px;
  padding: 10px;
  padding-right: 30px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
}

#search-list{
  width: 100%!important;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  height: calc(100vh - 100px);
  overflow-y:auto ;
}
#search-list ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
#search-list ul li{
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}
#search-list ul li:nth-child(even){
  background: #f5f5f5;
}
.result-title{
  font-size: 13px;
  color:black;
  margin: 0px !important;
}
.result-cat{
  font-size: 11px;
  color:#a0a0a0;
  margin:0px !important;
}


</style>
